
import Vue from 'vue';
import { addKpiCustomization } from '@/util/kpis';
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex';
import axios from 'axios';
export default {
  data() {
    return {
      heatMapMode: 'actualValue',
      lastTimeStamp: Date.now(),
      lastTimeStamp2: Date.now(),
      overViewKpiList: [],
      overviewLoading: false,
      apiTimeout: 0,
      lastDateUploaded: '',
      hasFetchedPref: false
      //
    };
  }, // data
  computed: {
    ...mapState('overview', { overViewKpis: 'kpis' }),
    ...mapGetters('filter', [
      'getStoreCodesList',
      'getDaysOfWeekList',
      'getStartingPeriod',
      'getEndingCompared',
      'getStartingCompared',
      'getEndingPeriod',
      'getCustomCalenderState'
    ]),
    ...mapState('user', ['kpiPreferences', 'kpiLabelValue', 'getLocale']),
    ...mapState('traffic', [
      'entranceMode',
      'entranceAverage',
      'powerHourMode',
      'entranceInout'
    ]),
    ...mapState('overview', ['allTrends']),
    ...mapState('prediction', ['traffic', 'kpiPrediction']),
   
  },
  watch: {
    kpiPreferences(newValue) {
      this.overViewKpiList = this.kpis && addKpiCustomization(this.kpis);
    },
    getStartingPeriod: {
      handler(newQuestion) {
        this.refreshAction();
      }
      // immediate: true
    }
    // getStartingPeriod(newValue, oldValue) {
    //   console.log("getStartingPeriod watcher", newValue, oldValue)
    // },
  },
  async mounted() {
    Vue.prototype?.$mixpanel?.track('traffic_report', {
      referrer: document.referrer
    });
    // alert('test2');
    // await this.fetchAllTrafficFidaOnChange();
  },

  methods: {
    ...mapActions('traffic', ['fetchAllTrafficFida', 'fetchAllTrafficFidaV2']),
    ...mapMutations('traffic', [
      'setEntranceMode',
      'setEntranceAverage',
      'setPowerHourMode',
      'setentranceInout'
    ]),
    ...mapActions('overview', ['fetchOverview', 'setDataByLocationFormatKey']),
    ...mapActions('user', ['fetchKpiPreferences']),
    ...mapActions('prediction', [
      'fetchKpiPrediction'
    ]),
    setHeatMapMode(newValue) {
      this.heatMapMode = newValue;
    },
    async refreshPage() {
      this.lastTimeStamp = Date.now();
      await this.fetchAllTrafficFidaOnChange();
      clearTimeout(this.apiTimer);
      this.apiTimer = setTimeout(this.refreshAction, 200);
    }, // refreshPage
    async fetchAllTrafficFidaOnChange() {
      try {
        await this.fetchAllTrafficFidaV2({
          //fetchAllTrafficFidaV2
          startDate: this.getStartingPeriod,
          endDate: this.getEndingPeriod,
          daysOfWeek: this.getDaysOfWeekList,
          storeCodes: this.getStoreCodesList,
          storeCodeList: this.getStoresCodeName
        });
        
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    //
    getParamsForOverview() {
      let stC = this.getStoreCodesList;
      if (!stC) {
        return;
      }
      return {
        startDate: this.getStartingPeriod,
        endDate: this.getEndingPeriod,
        compareStartDate: this.getStartingCompared,
        compareEndDate: this.getEndingCompared,
        storeCodes: stC,
        daysOfWeek: this.getDaysOfWeekList,
        useCustomCalender: this.getCustomCalenderState,
        groupByMetricKey: true,
        comparisonYKPIMetricKey: ([...this.kpiLabelValue][0] ?? {})?.value
        // kpis: this.customSort.join(',')
      };
    },
    async calcLastTimeStamp() {
      const apiUrl =
        'https://flow-public-resource.s3.ap-northeast-1.amazonaws.com/data/LastModifiedStore.json';
      const config = {
        headers: {
          Authorization: ''
        }
      };
      return JSON.stringify(await axios.get(apiUrl, config));
    },
    async refreshAction() {
      clearTimeout(this.apiTimeout);
      const _this = this;
      this.apiTimeout = setTimeout(async function() {
        await _this.fetchKpiPreferences();
        setTimeout(
          async () => {
            _this.lastTimeStamp2 = Date.now();
            _this.refreshOverview();
            // _this.refreshBoxTarget();
            _this.lastDateUploaded = await _this.calcLastTimeStamp();
            _this.hasFetchedPref = true;
          },
          _this.hasFetchedPref ? 0 : 1000
        );
      }, 500);
    },
    isInvalidNumber(val) {
            return Number.isNaN(val) || !Number.isFinite(val);
          },
    async refreshOverview() {
      try {
        this.overviewLoading = true;

        let params = this.getParamsForOverview();
        if (params) {
          let str = JSON.stringify(params);

          params.trackLatest = { callerId: 'traffic', value: str };

          let isActionable = await this.fetchOverview(params);
          await this.fetchKpiPrediction({
            metricKey: 'traffic',
            storeCodes: this.getStoreCodesList,
            daysOfWeek: this.getDaysOfWeekList
          });
          
          if (isActionable) {
            const kpiA = this.kpiPrediction?.predictionData.reduce((a, c) => a + Number(c.value), 0)
            const kpiB = this.kpiPrediction?.predictionDataC.reduce((a, c) => a + Number(c.value), 0)
            const sumP = this.isInvalidNumber(kpiA) ?0:kpiA;
            const sumC = this.isInvalidNumber(kpiB) ? 0:kpiB;
            const a = (sumP/sumC*100);
            const b = sumP-sumC;
            const va = this.isInvalidNumber(a) ? 0: a?.toFixed(2);
            const vaC = this.isInvalidNumber(b) ? 0: b;
            
            const placeholderObj = {
                comparedValue: sumC?.toLocaleString(),
                explanation: this.$store.state.user.translate.seven_day_traffic_forecast_tooltip_text,
                growthIndicator: sumP > sumC ?1:-1,
                metricID: '7d',
                metricKey: '7d',
                metricKeyShort: this.$store.state.user.translate.seven_day_traffic_forecast,
                order: 3,
                title: this.$store.state.user.translate.seven_day_traffic_forecast,
                value: sumP?.toLocaleString(),
                variance: va + "%",
                variation: vaC,
                variationA: vaC?.toLocaleString(),
                variationP: va + "%",
                variationPercentage: va + "%",
                videoUrl: "",
                visibility: 1,
              };
              const s = ['trf','7d', 'dw', ]
              const sortOrder = { "trf": 1, "dw": 2,"7d": 3 };
            this.overViewKpiList = [...addKpiCustomization(this.overViewKpis)?.filter(item => s.includes(item.metricID)), placeholderObj]?.sort((a, b) => {
             return (sortOrder[a.metricKey]) - (sortOrder[b.metricKey]);
            });
            this.overviewLoading = false;
          }
        } else {
          setTimeout(this.refreshOverview, 1000);
        }
      } catch (e) {
        this.$q.notify({
          type: 'negative',
          position: 'top',
          message: `${e}`
        });
      }
    }
  } // methods
}; // export default
